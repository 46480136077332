<template>
  <div class="auth-wrapper auth-v2">
    <b-overlay
      :show="loading"
      rounded="sm"
      opacity="0.95"
      class="w-100"
    >
      <b-row class="auth-inner m-0">

        <!-- Brand logo-->
        <b-link
          class="brand-logo d-flex align-items-center"
        >
          <b-img
            src="../../../assets/images/logo/starworks.png"
            style="height: 40px;"
          />
        </b-link>
        <!-- /Brand logo-->

        <!-- Left Text-->
        <b-col
          class="d-none d-lg-flex align-items-center p-5"
          lg="6"
        >
          <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
            <b-img
              :src="imgUrl"
              alt="Login V2"
              fluid
            />
          </div>
        </b-col>
        <!-- /Left Text-->

        <!-- Login-->
        <b-col
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
          lg="6"
        >
          <b-col
            class="px-xl-2 mx-auto"
            lg="6"
            md="6"
            sm="6"
          >
            <div
              v-if="currentdomain==='impunjab'"
              class="d-flex align-items-center justify-content-center"
            >
              <b-img
                src="../../../assets/images/logo/imp.png"
                style="width:60%"
              />
            </div>
            <div
              v-else-if="currentdomain==='ginserv'"
              class="d-flex align-items-center justify-content-center"
            >
              <b-img
                src="../../../assets/images/logo/ginserv.png"
                style="width:60%"
              />
            </div>
            <div
              v-else-if="currentdomain==='aghub'"
              class="d-flex align-items-center justify-content-center"
            >
              <b-img
                src="../../../assets/images/logo/aghub.png"
                style="width:60%"
              />
            </div>
            <div
              v-else-if="currentdomain==='actionplaniitb'"
              class="d-flex align-items-center justify-content-center"
            >
              <b-img
                src="../../../assets/images/logo/abhyuday.png"
                style="width:60%"
              />
            </div>
            <div
              v-else-if="currentdomain==='aic-prestige'"
              class="d-flex align-items-center justify-content-center"
            >
              <b-img
                src="../../../assets/images/logo/aic-prestige.png"
                style="width:60%"
              />
            </div>
            <div
              v-else
              class="d-flex align-items-center justify-content-center"
            >
              <b-img
                src="../../../assets/images/logo/starworks.jpg"
                style="width:60%"
              />
            </div>
            <!-- <b-card-title
              class="mb-1 mt-4 font-weight-bold text-center"
              title-tag="h2"
            >
              Sign In 👋
            </b-card-title> -->
            <b-card-text
              class="mt-4 mb-2 text-center"
              style="font-weight:bolder;"
            >
              Please continue to your account and start the adventure 🚀
            </b-card-text>
            <div style="display: flex;justify-content: center;">
              <b-button
                variant="light"
                style="display:flex;align-items:center;"
                class="mr-2 px-4"
                @click="signInWithGoogle"
              >
                <b-img
                  src="../../../assets/images/icons/google.svg"
                  style="width:20px;margin-right:6px;"
                />
                <span style="color:#555;padding-top:2px;">Google</span>
              </b-button>
            </div>
            <b-row class="mt-2">
              <b-col
                sm="5"
                class="d-flex align-items-center"
              >
                <hr style="width:100%;border-color:#6e6b7b;">
              </b-col>
              <b-col sm="2">
                <b-card-text
                  class="d-flex align-items-center justify-content-center text-center"
                  style="font-weight:bolder;height:100%;"
                >
                  Or
                </b-card-text>
              </b-col>
              <b-col
                sm="5"
                class="d-flex align-items-center"
              >
                <hr style="width:100%;border-color:#6e6b7b;">
              </b-col>
            </b-row>
            <validation-observer
              ref="loginForm"
              v-slot="{invalid}"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="formState !== 'EMAIL_INPUT' ? handleFormExecution() : loginnext()"
              >
                <!-- email -->
                <b-form-group
                  label="Email"
                  label-for="login-email"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    rules="required|email"
                    vid="email"
                  >
                    <b-input-group>
                      <b-form-input
                        id="login-email"
                        v-model="userEmail"
                        :state="errors.length > 0 ? false:null"
                        name="login-email"
                        :disabled="loadingUserStatus === true"
                        placeholder="john@example.com"
                        :autocomplete="formState === 'EMAIL_INPUT' ? 'username' : ''"
                        @update="formState = 'EMAIL_INPUT'"
                      />
                    </b-input-group>
                    <b-progress
                      v-show="loadingUserStatus"
                      animated
                      class="mt-50"
                      height="0.5rem"
                      value="100"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group v-if="formState === 'SIGN_UP'">
                  <div class="d-flex justify-content-between">
                    <label for="login-fullname">Full Name</label>
                  </div>
                  <validation-provider
                    v-slot="{ errors }"
                    name="FullName"
                    rules="required"
                    vid="fullname"
                  >
                    <b-input-group
                      :class="errors.length > 0 ? 'is-invalid':null"
                      class="input-group-merge"
                    >
                      <b-form-input
                        id="login-fullname"
                        v-model="fullname"
                        :state="errors.length > 0 ? false:null"
                        type="text"
                        class="form-control-merge"
                        name="login-fullname"
                        placeholder="Full Name"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!--  password  -->
                <b-form-group v-show="formState === 'SIGN_IN' || formState === 'SIGN_UP'">
                  <div class="d-flex justify-content-between">
                    <label for="login-password">{{ formState === 'SIGN_IN' ? 'Password' : 'Create Password' }}</label>
                  </div>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Password"
                    :autocomplete="formState === 'SIGN_IN' ? 'current-password' : 'new-password'"
                    vid="password"
                    :rules="formState !== 'EMAIL_INPUT' ? 'required' : ''"
                  >
                    <b-input-group
                      :class="errors.length > 0 ? 'is-invalid':null"
                      class="input-group-merge"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false:null"
                        :type="passwordFieldType"
                        class="form-control-merge"
                        name="login-password"
                        :placeholder="formState === 'SIGN_IN' ? 'Password' : 'Create Password'"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if=" formState === 'SIGN_UP'">
                  <div class="d-flex justify-content-between">
                    <label for="login-confirmPassword">Confirm Password</label>
                  </div>
                  <validation-provider
                    v-slot="{ errors }"
                    name="confirmPassword"
                    rules="required"
                    vid="password"
                  >
                    <b-input-group
                      :class="errors.length > 0 ? 'is-invalid':null"
                      class="input-group-merge"
                    >
                      <b-form-input
                        id="login-confirmPassword"
                        v-model="confirmPassword"
                        :state="errors.length > 0 ? false:null"
                        type="password"
                        class="form-control-merge"
                        name="login-confirmPassword"
                        placeholder="Confirm Password"
                        autocomplete="new-password"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  v-if="resetCodeSent"
                  label="Confirmation Code"
                  label-for="confirmation-code"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Confirmation Code"
                    rules="required"
                  >

                    <b-form-input
                      id="confirmation-code"
                      v-model="resetCode"
                      :state="errors.length > 0 ? false:null"
                      name="confirmation-code"
                      placeholder="012345"
                    />
                    <small class="text-danger">{{ errors[0] }}<br></small>
                    <small v-if="showResendCode">Haven't received the verification code?
                      <b-link
                        variant="link"
                        @click="resendVerificationCode()"
                      >Resend Code
                      </b-link>
                    </small>
                    <small
                      v-if="!showResendCode"
                      class="text-muted"
                    >You can request new code after 30 seconds.</small>
                  </validation-provider>
                </b-form-group>

                <b-button
                  v-if="formState==='EMAIL_INPUT'"
                  :disabled="invalid || loading"
                  block
                  type="button"
                  variant="primary"
                  @click="loginnext"
                >
                  Next
                </b-button>
                <b-button
                  v-if="formState==='SIGN_IN' || formState==='SIGN_UP'"
                  :disabled="invalid || loading || (password !== confirmPassword && formState === 'SIGN_UP')"
                  block
                  type="submit"
                  variant="primary"
                >
                  <b-spinner
                    v-if="loading"
                    small
                  />
                  {{ formState === 'SIGN_IN' ? 'Sign in' : 'Sign up' }}
                </b-button>
              </b-form>
            </validation-observer>

            <!-- <b-card-text class="text-center mt-2">
              <span>New on our platform? </span>
              <b-link :to="{name:'auth-register', query: $route.query}">
                <span>&nbsp;Create an account</span>
              </b-link>
            </b-card-text> -->
            <b-card-text
              class="text-center mt-2"
              style="margin-top:-10px"
            >
              <span>Forgot Password? </span>
              <b-link :to="{name:'auth-forgot-password', query: $route.query}">
                <span>&nbsp;Reset it Here</span>
              </b-link>
            </b-card-text>

          </b-col>
        </b-col>
        <!-- /Login-->
      </b-row>
      <template #overlay>
        <div class="text-center">
          <p class="display-4">
            "Give me a firm spot on which to stand, and I shall move the earth"
            <br>
            <span class="h3">— Archimedes of Syracuse</span>
          </p>

          <div class="d-inline-flex">
            <b-spinner
              variant="primary"
              class="my-1"
            />
          </div>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BButton,
  BCardText,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BOverlay,
  BProgress,
  BRow,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { email, required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import gql from 'graphql-tag'
import jwtDecode from 'jwt-decode'
import { Auth } from 'aws-amplify'
import axios from 'axios'
import apiConfig from '@/config'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BSpinner,
    BProgress,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BOverlay,
    BCardText,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      currentdomain: '',
      loading: false,
      loadingUserStatus: false,
      resetCodeSent: false,
      status: '',
      password: '',
      confirmPassword: '',
      userEmail: '',
      fullname: '',
      resetCode: '',
      incubator: [],
      incubatorName: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      randomFact: '',
      // validation rules
      required,
      email,
      userInfo: [],
      pageState: 0,
      formState: 'EMAIL_INPUT',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  beforeMount() {
    this.getHost()
  },
  mounted() {
    this.checkAuthState()
  },
  methods: {
    async checkAuthState() {
      try {
        await Auth.currentAuthenticatedUser()
          .then(user => this.handlePostLogin(user))
      } catch (error) {
        // console.log(error)
      }
    },
    handleFormExecution() {
      if (this.formState === 'SIGN_IN') {
        this.login()
      } else {
        this.register()
      }
    },
    getHost() {
      this.currentdomain = window.location.host.split('.')[0].toString()
    },
    async loginnext() {
      await this.$refs.loginForm.validate()
        .then(success => {
          if (success) {
            this.checkUserStatus()
          }
        })
    },
    async checkUserStatus() {
      this.loadingUserStatus = true
      try {
        const isExist = await axios.post(apiConfig.USER_EXISTENCE_API_URL, {
          email: this.userEmail,
        })

        if (isExist.data.success) {
          this.formState = 'SIGN_IN'
        } else {
          this.formState = 'SIGN_UP'
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.formState = 'SIGN_UP' // User not found
        }
      } finally {
        this.loadingUserStatus = false
      }
    },
    signInWithGoogle() {
      const redirectTo = 'redirectURL=/onboarding'
      Auth.federatedSignIn({ provider: 'Google', customState: redirectTo })
    },
    login() {
      this.$refs.loginForm.validate()
        .then(success => {
          if (success) {
            this.loading = true
            useJwt.login(this.userEmail, this.password)
              .then(response => this.handlePostLogin(response))
              .catch(error => {
                this.loading = false
                this.$refs.loginForm.setErrors({
                  email: error.message,
                  password: ' ',
                })
              })
          }
        })
    },
    register() {
      if (this.resetCodeSent) {
        return Auth.confirmSignUp(this.userEmail, this.resetCode)
          .then(data => {
            this.loading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: data === 'SUCCESS' ? 'Registration successful' : 'Failed to register',
                icon: data === 'SUCCESS' ? 'CheckIcon' : 'XIcon',
                variant: data === 'SUCCESS' ? 'success' : 'danger',
              },
            })
            if (data === 'SUCCESS') {
              useJwt.login(this.userEmail, this.password)
                .then(response => {
                  this.handlePostLogin(response)
                })
                .catch(error => {
                  this.loading = false
                  this.$refs.loginForm.setErrors({
                    email: error.message,
                    password: ' ',
                  })
                })
              // this.$router.replace({ name: 'auth-login', query: { redirect: this.$route.query.redirect } })
            }
          })
          .catch(err => {
            this.loading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: err,
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          })
      }
      return this.$refs.loginForm.validate()
        .then(success => {
          if (success) {
            Auth.signUp({
              username: this.userEmail,
              password: this.password,
              attributes: {
                name: this.fullname,
              },
            })
              .then(({ codeDeliveryDetails }) => {
                this.loading = false
                this.resetCodeSent = true
                setTimeout(() => {
                  this.showResendCode = true
                }, 30000)
                return this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `The confirmation code has been sent on your ${codeDeliveryDetails.AttributeName}`,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
              })
              .catch(err => {
                this.loading = false
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: err,
                    icon: 'XIcon',
                    variant: 'danger',
                  },
                })
                this.$refs.registerForm.setErrors(err.response.data.error)
              })
          }
        })
    },
    resendVerificationCode() {
      this.loading = true
      this.showResendCode = false
      setTimeout(() => {
        this.showResendCode = true
      }, 30000)
      return Auth.resendSignUp(this.userEmail)
        .then(() => {
          this.loading = false
          return this.$toast({
            component: ToastificationContent,
            props: {
              title: 'The confirmation code has been sent again',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(err => {
          this.loading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
    handlePostLogin(response) {
      if (response === null) return
      const userData = {}
      userData.fullName = response.attributes.name
      userData.userEmail = response.attributes.email
      if (jwtDecode(response.signInUserSession.idToken.jwtToken)['cognito:groups']?.includes('Superadmin')) {
        userData.role = 'admin'
        userData.ability = [{
          action: 'manage',
          subject: 'Incubator',
        },
        {
          action: 'read',
          subject: 'Auth',
        },
        {
          action: 'read',
          subject: 'Dashboard',
        }]
      } else if (jwtDecode(response.signInUserSession.idToken.jwtToken)['cognito:groups']?.includes('Mentor')) {
        userData.role = 'mentor'
        userData.ability = [
          {
            action: 'read',
            subject: 'Auth',
          },
          {
            action: 'read',
            subject: 'Dashboard',
          },
          {
            action: 'manage',
            subject: 'Mentor',
          }]
      } else {
        userData.role = 'user'
        userData.ability = [
          {
            action: 'read',
            subject: 'Auth',
          },
          {
            action: 'read',
            subject: 'Dashboard',
          },
          {
            action: 'manage',
            subject: 'User',
          }]
        this.$apollo.query({
          query: gql`
                        {
                          users_customuser(where: {email: {_eq: "${response.attributes.email}"}}) {
                            id
                            first_name
                            partially_complete
                            email
                            timezone
                            user_id
                            users_associationtables{
                              id
                              organization_id
                              program_id
                              role
                              designation
                              users_organizationtable {
                                id
                                title
                                type
                                domain
                              }
                            }
                          }
                        }`,
        })
          .then(({ data }) => {
            userData.id = data.users_customuser[0].id
            userData.timezone = JSON.parse(data.users_customuser[0].timezone)
            userData.user_id = data.users_customuser[0].user_id
            userData.partially_complete = data.users_customuser[0].partially_complete
            userData.associatedOrgDetails = data.users_customuser[0].users_associationtables
            // ? This is just for demo purpose as well.
            // ? Because we are showing eCommerce app's cart items count in navbar
            // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)
            localStorage.setItem('userData', JSON.stringify(userData))
            localStorage.setItem('accesstoken', JSON.stringify(jwtDecode(response.signInUserSession.idToken.jwtToken)))
            this.loading = false
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Welcome ${userData.fullName || ''}`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
              },
            })
            if (localStorage.getItem('redirectAfterLogin')) {
              const redirectURL = localStorage.getItem('redirectAfterLogin')
              localStorage.removeItem('redirectAfterLogin')
              this.$router.push(redirectURL)
            } else if (data.users_customuser[0].partially_complete) {
              window.location.href = this.$route.query.redirect ? decodeURIComponent(this.$route.query.redirect) : '/'
            } else {
              window.location.href = '/onboarding'
            }
          })
      }
      if (jwtDecode(response.signInUserSession.idToken.jwtToken)['cognito:groups']?.includes('Superadmin') || jwtDecode(response.signInUserSession.idToken.jwtToken)['cognito:groups']?.includes('Mentor')) {
        localStorage.setItem('userData', JSON.stringify(userData))
        localStorage.setItem('accesstoken', JSON.stringify(jwtDecode(response.signInUserSession.idToken.jwtToken)))
        this.$ability.update(userData.ability)
        this.$apollo.query({
          query: gql`
                        {
                          users_customuser(where: {email: {_eq: "${response.attributes.email}"}}) {
                            id
                            first_name
                            email
                            timezone
                            users_associationtables{
                              id
                              organization_id
                              program_id
                              role
                              designation
                              users_organizationtable {
                                id
                                title
                                type
                                domain
                              }
                            }
                          }
                        }`,
        })
          .then(({ data }) => {
            userData.id = data.users_customuser[0].id
            userData.timezone = JSON.parse(data.users_customuser[0].timezone)
            userData.associatedOrgDetails = data.users_customuser[0].users_associationtables
            // ? This is just for demo purpose as well.
            // ? Because we are showing eCommerce app's cart items count in navbar
            // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)
            localStorage.setItem('userData', JSON.stringify(userData))
            localStorage.setItem('accesstoken', JSON.stringify(jwtDecode(response.signInUserSession.idToken.jwtToken)))
            this.loading = false
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Welcome ${userData.fullName || userData.username}`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
              },
            })
            if (localStorage.getItem('redirectAfterLogin')) {
              const redirectURL = localStorage.getItem('redirectAfterLogin')
              localStorage.removeItem('redirectAfterLogin')
              this.$router.push(redirectURL)
            } else {
              window.location.href = this.$route.query.redirect ? decodeURIComponent(this.$route.query.redirect) : '/'
            }
          })
      }
    },
  },
  apollo: {
    incubator: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
          {
            users_organizationtable(where: {domain: {_regex: "${subdomain}"}}) {
              id
              logo
              title
              registered_title
            }
          }`
      },
      update: data => {
        this.incubatorName = data.registered_title || data.title || 'Starworks Prime'
        return data.users_organizationtable
      },
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
